export const phoneRegex = {
  mobile: {
    AL: /^\+355\d{7,20}$/,
    AO: /^\+244\d{9}$/,
    AT: /^\+436(?:44|50|51|52|53|55|57|59|60|61|63|64|647|6475|648|65|66|67|68|680|681|688|69|70|76|768|7681|7682|7683|7684|7685|7686|7687|7688|7689|77|78|80|81|812|818|86|88|89|90|991|991599|992|998|9988|9989)[\d]{6,10}$/,
    AU: /^\+61(?!\d*(\d)\1{7}\d*)4\d{8}$/,
    BA: /^\+387\d{7,20}$/,
    BE: /^\+324\d{8}$/,
    BH: /^\+973(?:3[28934567]|66)\d{6}$/,
    BG: /^\+359\d{7,20}$/,
    BR: /^\+55[1-9]\d9\d{8}$/,
    CA: /^\+1[2-9]\d{9}$/,
    CH: /^\+41(?:74|75|76|77|78|79)\d{7}$/,
    CZ: /^\+420[67]\d{8}$/,
    CV: /^\+238\d{7}$/,
    DE: /^\+49(?:15|16|17)\d{8,9}$/,
    DK: /^\+45(?:2|3|4|5|6|7|8|9)\d{7}$/,
    EE: /^\+372\d{7,8}$/,
    ES: /^\+34(?:6\d{8}|7[1234]\d{7})$/,
    FI: /^\+358[45]\d{5,9}$/,
    FO: /^\+298\d{6}$/,
    FR: /^\+33[67]\d{8}$/,
    GB: /^\+447\d{9}$/,
    GR: /^\+3069\d{8}$/,
    GL: /^\+299\d{6}$/,
    GW: /^\+2459[567]{1}\d{7}$/,
    HR: /^\+3859\d{7,8}$/,
    HU: /^\+36(?:20|30|31|50|70)\d{7}$/,
    IE: /^\+353(?:82|83|84|85|86|87|88|89)\d{7}$/,
    IN: /^\+91[6789]\d{9}$/,
    IT: /^\+393\d{8,9}$/,
    JO: /^\+962\d{9}$/,
    JP: /^\+81[6-9]0\d{8}$/,
    KR: /^\+821[016789]\d{7,8}$/,
    KW: /^\+965\d{8}$/,
    KZ: /^\+77\d{9}$/,
    LI: /^\+423[567]\d{6}$/,
    MA: /^\+212\d{9}$/,
    ME: /^\+382\d{7,20}$/,
    MK: /^\+389\d{7,20}$/,
    MX: /^\+52[2-9]\d{9}$/,
    MZ: /^\+258\d{8,9}$/,
    NL: /^\+316\d{8}$/,
    NO: /^\+47[49]\d{7}$/,
    PH: /^\+63\d{8}$/,
    PL: /^\+48[45678]\d{8}$/,
    PT: /^\+3519\d{8}$/,
    RO: /^\+407\d{7,8}$/,
    RS: /^\+3816\d{7,8}$/,
    RU: /^\+7[79]\d{9}$/,
    SA: /^\+9665\d{8}$/,
    SE: /^\+467\d{8}$/,
    SG: /^\+65\d{7,14}$/,
    SI: /^\+386(?:[347][01]|6[4589]|51)\d{6}$/,
    SK: /^\+4219\d{8}$/,
    ST: /^\+2399\d{6}$/,
    US: /^\+1[2-9]\d{9}$/,
    UA: /^\+380[1-9]\d{8}$/,
    ZA: /^\+27\d{9}$/,
    R1: /^\+\d{7,14}$/,
    'USA/CA': /^\+1[2-9]\d{9}$/,
    default: /^\+\d{7,14}$/
  },
  get landline() {
    return {
      ...this.mobile,
      AL: /^\+355\d{7,20}$/,
      AO: /^\+244\d{9}$/,
      AT: /^\+436(?:44|50|51|52|53|55|57|59|60|61|63|64|647|6475|648|65|66|67|68|680|681|688|69|70|76|768|7681|7682|7683|7684|7685|7686|7687|7688|7689|77|78|80|81|812|818|86|88|89|90|991|991599|992|998|9988|9989)[\d]{6,10}$/,
      AU: /^\+61(?=\d{9})(\d)\d*(?!\1)(\d)\d*(?!\1|\2)(\d)\d*(?!\1|\2|\3)(\d)\d*(?!\1|\2|\3|\4)(\d)\d*$/,
      BA: /^\+387\d{7,20}$/,
      BE: /^\+32\d{8}$/,
      BH: /^\+973(?:3[28934567]|66)\d{6}$/,
      BG: /^\+359\d{7,20}$/,
      BR: /^\+55[1-9]\d9\d{8}$/,
      CA: /^\+1[2-9]\d{9}$/,
      CH: /^\+41(?:74|75|76|77|78|79)\d{7}$/,
      CZ: /^\+420(?:6|7)\d{8}$/,
      CV: /^\+238\d{7}$/,
      DE: /^\+49\d{10,13}$/,
      DK: /^\+45(?:20|30|31|40|41|42|50|51|52|53|60|61|71|81|91|92|93)\d{6}$/,
      EE: /^\+372\d{7,8}$/,
      ES: /^\+34[89]\d{8}$/,
      FI: /^\+358(?:4|5)\d{8,9}$/,
      FO: /^\+298\d{6}$/,
      FR: /^\+33(?:6|7)\d{8}$/,
      GB: /^\+447\d{9}$/,
      GR: /^\+30\d{10}$/,
      GL: /^\+299\d{6}$/,
      GW: /^\+2459[567]{1}\d{7}$/,
      HR: /^\+\d{7,14}$/,
      HU: /^\+36\d{8,9}$/,
      IE: /^\+353\d{9,10}$/,
      IN: /^\+91[6789]\d{9}$/,
      IT: /^\+393\d{8,9}$/,
      JO: /^\+962\d{9}$/,
      JP: /^\+81(([1-9][1-9]\d{3}|[1-9]{2}\d{3}|[1-9]{2}\d?\d{2}|[1-9]{2}\d{2}\d)\d{4}|[5-9]0?\d{4}\d{4})$/,
      KR: /^\+821[016789]\d{7,8}$/,
      KW: /^\+965\d{8}$/,
      KZ: /^\+77\d{9}$/,
      LI: /^\+423\d{7}$/,
      MA: /^\+212\d{9}$/,
      ME: /^\+382\d{7,20}$/,
      MK: /^\+389\d{7,20}$/,
      MX: /^\+52[2-9]\d{9}$/,
      MZ: /^\+258\d{8,9}$/,
      NL: /^\+316\d{8}$/,
      NO: /^\+47\d{8}$/,
      PH: /^\+63\d{8}/,
      PL: /^\+48[45678]\d{8}$/,
      PT: /^\+351\d{9}$/,
      RO: /^\+407\d{7,8}$/,
      RS: /^\+3816\d{7,8}$/,
      RU: /^\+7\d{10,14}$/,
      SA: /^\+9665\d{8}$/,
      SE: /^\+46[1-9]{7,13}$/,
      SI: /^\+386[1-9][0-9]{6,7}$/,
      SK: /^\+4219\d{8}$/,
      ST: /^\+2399\d{6}$/,
      US: /^\+1[2-9]\d{9}$/,
      UA: /^\+380[1-9]\d{8}$/,
      ZA: /^\+27\d{9}$/,
      R1: /^\+\d{7,14}$/
    };
  }
};
