import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Text, Link, Row, Col } from 'Common';
import Modal, { Sheets, ModalHeader, ModalBody } from '@ingka/modal';
import { t } from 'Utils/localization/i18next';
import styles from './TextWithLink.scss';

export const TextWithLink = ({
  labelBeforeLink,
  labelAfterLink,
  labelLink,
  link,
  linkTarget,
  lineBreak,
  labelBetweenLinks,
  linkLabels,
  links,
  name,
  ...rest
}) => {
  const [openMoreInfoModal, setOpenMoreInfoModal] = useState(false);
  const labelBetweenLinksExist = !!labelBetweenLinks;
  const LabelWithLink = () => {
    return (
      <Text {...rest}>
        {labelBeforeLink}
        {lineBreak && <br />}
        {link && (
          <Link underline url={link} target={linkTarget}>
            {t(labelLink)}
          </Link>
        )}
        {labelAfterLink}
      </Text>
    );
  };
  const LabelWithLinks = () => {
    const ClassNameFunction = index => {
      if (index === 1) {
        return 'first-text-style';
      }
      if (index === 3) {
        return 'second-text-style';
      }
      return '';
    };
    const TextSpaceFunction = index => {
      if (index < 4) return ' ';
      return '';
    };
    return (
      <Text {...rest}>
        {labelBeforeLink}{' '}
        {labelBetweenLinks.map((labelBetweenLink, index) => {
          const labelInsideLink = linkLabels[index];
          const linkInsideLink = links[index];
          return name === 'allowSMS' ? (
            <span key={index}>
              {lineBreak && <br />}
              {t(labelBetweenLink)}{' '}
              <Link url={t(linkInsideLink)} target="_blank">
                {t(labelInsideLink)}.
              </Link>{' '}
            </span>
          ) : (
            <span key={index} className={styles[ClassNameFunction(index)]}>
              {lineBreak && <br />}
              {t(labelBetweenLink)}
              {TextSpaceFunction(index)}
            </span>
          );
        })}
        {CONFIG.FEATURE.ENABLE_SHOWMORE && (
          <>
            {' '}
            <Link onClick={() => setOpenMoreInfoModal(true)} target="_blank">
              {t('dashboard.upgrade.checkbox.moreInfo')}
            </Link>
            {openMoreInfoModal && (
              <Modal
                visible={openMoreInfoModal}
                escapable={true}
                handleCloseBtn={() => {
                  setOpenMoreInfoModal(false);
                }}
                prefix={CONFIG.APP.STYLES_PREFIX}
              >
                <Sheets
                  header={
                    <ModalHeader
                      ariaCloseTxt="Close prompt"
                      backBtnClick={() => setOpenMoreInfoModal(false)}
                    />
                  }
                  prefix={CONFIG.APP.STYLES_PREFIX}
                >
                  <ModalBody>
                    <Row>
                      <Col md={12}>
                        <Text>
                          {t('dashboard.upgrade.checkbox.moreInfoText')}
                        </Text>
                      </Col>
                    </Row>
                  </ModalBody>
                </Sheets>
              </Modal>
            )}
          </>
        )}
        {labelAfterLink}
      </Text>
    );
  };
  return labelBetweenLinksExist ? <LabelWithLinks /> : <LabelWithLink />;
};

TextWithLink.propTypes = {
  id: PropTypes.string,
  labelBeforeLink: PropTypes.string,
  name: PropTypes.string,
  labelBetweenLinks: PropTypes.arrayOf(PropTypes.string),
  linkLabels: PropTypes.arrayOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.string),
  labelAfterLink: PropTypes.string,
  labelLink: PropTypes.string,
  link: PropTypes.string,
  to: PropTypes.string,
  linkTarget: PropTypes.string,
  lineBreak: PropTypes.bool,
  // Props passed down to Text component
  inline: PropTypes.bool,
  bold: PropTypes.bool,
  italic: PropTypes.bool,
  center: PropTypes.bool,
  smaller: PropTypes.bool,
  margin: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
  marginTop: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
  marginBottom: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
  marginLeft: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
  marginRight: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
  colour: PropTypes.oneOf([
    'grey-500',
    'grey-700',
    'brand-blue',
    'feedback-error'
  ]),
  className: PropTypes.string,
  style: PropTypes.object
};
