import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Text } from 'Common';
import { t } from 'Utils/localization/i18next';
import SSRIcon from '@ingka/ssr-icon';
import checkmark from '@ingka/ssr-icon/paths/checkmark-small';
import cross from '@ingka/ssr-icon/paths/cross-small';
import styles from './CommunicationPreferences.scss';
import UnsubscribeSectionContainer from './UnsubscribeSectionContainer';
import PersonalisedAds from './PersonalisedAds';

const NewsletterFieldReadContainer = props => {
  const swithFunc = param => {
    switch (param) {
      case 'allowEmail':
        return 'email';
      case 'allowSMS':
        return 'sms';
      case 'allowDirectMail':
        return 'directMail';
      case 'allowPhone':
        return 'phone';
      case 'allowAppPushNotification':
        return 'appNotification';
    }
    return '';
  };
  return (
    <div>
      <Text
        marginBottom="s"
        className={styles['communication-preferences-description']}
      >
        {t('dashboard.communications.newsletter-section.subtitle')}
      </Text>
      <Text marginBottom="s">
        <div>
          {props.subscriptions.map((element, index) => {
            return (
              <Text key={index}>
                {props.profile[`${element}`] ? (
                  <Text className={styles['sub-category-value']}>
                    <span className={styles['subscribed-indicator']}>
                      <SSRIcon
                        prefix={CONFIG.APP.STYLES_PREFIX}
                        size="100"
                        paths={checkmark}
                      />
                    </span>
                    {t(
                      `dashboard.communications.newsletter-section.allow.${swithFunc(
                        element
                      )}`
                    )}
                  </Text>
                ) : (
                  <Text className={styles['sub-category-value']}>
                    <span className={styles['unsubscribed-indicator']}>
                      <SSRIcon
                        prefix={CONFIG.APP.STYLES_PREFIX}
                        size="100"
                        paths={cross}
                      />
                    </span>
                    {t(
                      `dashboard.communications.newsletter-section.dont-allow.${swithFunc(
                        element
                      )}`
                    )}
                  </Text>
                )}
              </Text>
            );
          })}
        </div>
      </Text>
      {!props.hidePersonalisedAds && <PersonalisedAds />}
      {!props.hidePreferredLanguage && (
        <div className={styles['preferred-language']}>
          <p className={styles['sub-category-header']}>
            {t(`dashboard.communications.newsletter-section.preferredLanguage`)}
          </p>
          <Text className={styles['sub-category-value']}>
            {t(`forms.preferredLanguage_${props.profile.preferredLanguage}`)}
          </Text>
        </div>
      )}
      <Text marginBottom="l">
        <div>
          {!props.hideParticipateInCustomerPanel && (
            <>
              <Text
                className={styles['communication-preferences-description']}
                marginBottom="s"
              >
                {t(`forms.memberPanelNotJoined`)}
              </Text>
              {props.profile.participateInMemberPanel ? (
                <Text className={styles['sub-category-value']}>
                  <span className={styles['subscribed-indicator']}>
                    <SSRIcon
                      prefix={CONFIG.APP.STYLES_PREFIX}
                      size="100"
                      paths={checkmark}
                    />
                  </span>
                  {t(`forms.memberPanelJoined`)}
                </Text>
              ) : (
                <Text className={styles['sub-category-value']}>
                  <span className={styles['unsubscribed-indicator']}>
                    <SSRIcon
                      prefix={CONFIG.APP.STYLES_PREFIX}
                      size="100"
                      paths={cross}
                    />
                  </span>
                  {t(`forms.memberPanelNotJoined`)}
                </Text>
              )}
            </>
          )}
        </div>
      </Text>
      {!props.hideUnsubscribeAll && (
        <UnsubscribeSectionContainer allowAllConsent={props.allowAllConsent} />
      )}
    </div>
  );
};

NewsletterFieldReadContainer.propTypes = {
  profile: PropTypes.object,
  subscriptions: PropTypes.arrayOf(PropTypes.string),
  allowSMS: PropTypes.string,
  allowEmail: PropTypes.string,
  AllowDirectMail: PropTypes.string,
  hidePreferredLanguage: PropTypes.bool,
  hideParticipateInCustomerPanel: PropTypes.bool,
  hidePersonalisedAds: PropTypes.bool,
  allowAllConsent: PropTypes.bool,
  hideUnsubscribeAll: PropTypes.bool
};

NewsletterFieldReadContainer.defaultProps = {
  profile: {},
  subscriptions: [],
  hideParticipateInCustomerPanel: false,
  hidePreferredLanguage: false,
  allowAllConsent: false,
  hidePersonalisedAds: true,
  hideUnsubscribeAll: false
};

const mapStateToProps = state => {
  return {
    profile: state.profile
  };
};

export default connect(
  mapStateToProps,
  null
)(NewsletterFieldReadContainer);
