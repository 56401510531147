import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Routes from 'Routes/profileRoutes';
import { t } from 'Utils/localization/i18next';
import { Text, FlexPanel, Button } from 'Common';
import mask from 'Utils/mask/mask';
import SSRIcon from '@ingka/ssr-icon';
import warningTriangle from '@ingka/ssr-icon/paths/information-circle';
import styles from './SectionReadContainer.scss';

const MobileFieldReadContainer = props => {
  const placeholder =
    !props.profile[props.names[0]] &&
    t(`dashboard.placeholders.${props.names[0]}`);
  const maskedValue = props.profile[props.names[0]]
    ? `${mask(props.profile[props.names[0]], props.maskRules)}`
    : '';
  const sendOTP = `${Routes.SEND_OTP}`;
  return (
    <>
      <FlexPanel spread>
        {placeholder ? (
          <Text colour="grey-500">{placeholder}</Text>
        ) : (
          <div>
            <p className={styles['common-specifier']}>{t(`forms.mobile`)}</p>
            <div>
              <Text className={styles['common-value']}>{maskedValue}</Text>
            </div>
          </div>
        )}
      </FlexPanel>
      <FlexPanel className={styles['verification-panel']}>
        {CONFIG.FEATURE.ENABLE_PHONE_VERIFICATION && props.profile.mobile && (
          <Text
            bold
            colour={
              props.profile.phoneVerified
                ? 'feedback-confirmation'
                : 'semantic-caution'
            }
          >
            {props.profile.phoneVerified
              ? t('common.verified')
              : t('common.unverified')}
          </Text>
        )}
      </FlexPanel>
      {CONFIG.FEATURE.ENABLE_PHONE_VERIFICATION &&
        !props.profile.phoneVerified &&
        props.profile.mobile && (
          <FlexPanel className={styles['verification-panel-box']}>
            <SSRIcon
              prefix={CONFIG.APP.STYLES_PREFIX}
              className={styles['caution-icon']}
              paths={warningTriangle}
            />
            <Text>{t('common.verifyNumber')} </Text>
            <Button
              small
              onClick={() => {
                props.history.push(sendOTP);
              }}
            >
              {t('common.verificationSmsButton')}
            </Button>
          </FlexPanel>
        )}
    </>
  );
};

MobileFieldReadContainer.propTypes = {
  names: PropTypes.arrayOf(PropTypes.string),
  profile: PropTypes.object,
  history: PropTypes.object,
  maskRules: PropTypes.arrayOf(PropTypes.object)
};

const mapStateToProps = state => {
  return {
    profile: state.profile
  };
};

const MobileFieldReadContainerWithCOnnect = connect(
  mapStateToProps,
  null
)(MobileFieldReadContainer);

export default withRouter(MobileFieldReadContainerWithCOnnect);
