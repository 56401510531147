import React from 'react';
import PropTypes from 'prop-types';
import { TextWithLink } from 'Common';
import { t } from 'Utils/localization/i18next';

const ConsentInfo = props => (
  <TextWithLink
    label={t(props.label)}
    labelBeforeLink={`${t(props.labelBeforeLink)} `}
    labelAfterLink={` ${t(props.labelAfterLink)}`}
    labelLink={t(props.labelLink)}
    link={t(props.link)}
    linkTarget="_blank"
    lineBreak={props.lineBreak}
  />
);

ConsentInfo.propTypes = {
  label: PropTypes.string,
  labelBeforeLink: PropTypes.string,
  labelAfterLink: PropTypes.string,
  labelLink: PropTypes.string,
  link: PropTypes.string,
  lineBreak: PropTypes.bool
};

export default ConsentInfo;
